import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as gridUtilities from '../../../utilities/gridUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import { loggingLevels } from '../../../constants'; 
import { nodeRoutes } from '../../../constants';
import SearchDailyOrdersResultsPanel from './SearchDailyOrdersResultsPanel';
import rpcAction from '../../../actions/rpcAction';
export const PANEL_ID = 'searchDailyOrdersResultsPanel';
class SearchDailyOrdersResultsPanelContainer extends Component {
	render() {
		return (
			<SearchDailyOrdersResultsPanel {...this.props}
				panelId={PANEL_ID}
			/>
		);
	}
}

const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([props.screenId, 'SearchDailyOrdersResultsGrid', 'rowData']);
	return {
		rowData: rowData === undefined ? [] : rowData
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		getExcelDataSet: (gridRef) => { 
			const rowData = storeUtilities.getValue([props.screenId, 'SearchDailyOrdersResultsGrid', 'rowData']);
			const results = gridUtilities.excludeColumnsForExcelExportDataSet(gridRef.api.getColumns(), gridRef.api.getAllDisplayedColumns(), rowData, ['pdfUrl']);
			const obj = sharedUtilities.convertToJsonArray(results, undefined, 'COLUMNS', 'DATA');
			return obj;
		},
		onPdfCellClicked: (params) => {
			if (params.data.pdfUrl) {
				window.open(params.data.pdfUrl);
			}
		},
		getColumnValue: (params) => {
			const value = ((params.colDef.colId in params.data) ? params.data[params.colDef.colId] : undefined);
			let returnValue;
			switch(params.colDef.colId){
				/*regular date columns*/
				case 'ordDate':
				case 'shipDate':
				case 'arrivalDate':
				case 'salesBeginDate':
				case 'salesEndDate':
				case 'needByDate':
				case 'createdDateTime':
				case 'lastModifiedDateTime':
				case 'dateSent':
					if(!value || value?.trim && value.trim() === ''){
						returnValue = undefined;
					}
					else {
						returnValue = moment(value).toDate();
					}
					break;				
				/*all other columns*/
				default:
					returnValue = value;
			}
			return returnValue;
		},
		getFormattedColumnValue: (params) => {
			const value = ((params.colDef.colId in params.data) ? params.data[params.colDef.colId] : undefined);
			let returnValue;
			switch(params.colDef.colId){

				/*regular date columns*/
				case 'ordDate':
				case 'shipDate':
				case 'arrivalDate':
				case 'salesBeginDate':
				case 'salesEndDate':
				case 'needByDate':
				case 'createdDateTime':
				case 'lastModifiedDateTime':
				case 'dateSent':
					if(!value || value?.trim && value.trim() === '')
					{
						returnValue = '';
					} 
					else {
						returnValue = moment(value).format('MM/DD/YYYY');
					}
					break;
				/*all other columns*/
				default:
					returnValue = value;
			}
			return returnValue;
		},
		handleExportToExcelClicked: (dataset) => {
			if(dataset.length <= 0){
				sharedUtilities.showError('No data to export', 'You must click Search and have at least 1 result before clicking Export to Excel', 'ERROR');
				return;
			}
			dispatch(rpcAction({
				args: dataset,
				nodeRoute: nodeRoutes.DownloadExcel,
				method: 'POST',
				requestInBody: true,
				customResponseHandler: async (response) => {
					const now = moment().format('YYYY-MM-DD, h-mm.SSS A');
					const blob = await response.blob();
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement('a');
					a.href = url;
					a.download = 'Daily Orders ' + now + '.xlsx';
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();    
					a.remove(); //afterwards we remove the element again    
				}
			}));
		},
		getPdfInfo: (params, callback) => {
			const pathInCache = ['pdfInfo', params.node.data.poNum];
			storeUtilities.getCachedRpcCall({
				getPendingIdentifier: () => params.node.data.poNum,
				pathInCache: pathInCache,
				args: {poNumber: params.node.data.poNum},
				nodeRoute: nodeRoutes.IFRServiceNET,
				endpoint: '/Management/DailyOrderEmails',
				callback: (response) => {
					if(callback){
						callback(params, response);
					}
				},
				showLoadingMask: false,
				method: 'GET',
				hideLoadingMaskOnComplete: true,
				retryOnFailure: true,
				preventDuplicateRequests: false,
				expirationMs: 1000 /*ms*/ * 60 /*sec*/ * 60 /*min*/,/*1 hour*/
				overrideNodeName: 'PDF_INFO_STORE',
				storageExceptionHandler: () => {
					sharedUtilities.logMessage('Browser Storage is full', loggingLevels.error);;
				}
			});
		}
	};
};
SearchDailyOrdersResultsPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	searchDailyOrdersResultsGridRef: PropTypes.any,
	onSearchDailyOrdersResultsGridReady: PropTypes.func,
	getExcelDataSet: PropTypes.func,
	onPdfCellClicked: PropTypes.func,
	getColumnValue: PropTypes.func,
	getFormattedColumnValue: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SearchDailyOrdersResultsPanelContainer);

connectedComponent.defaultProps = {};

export default connectedComponent;