import { cloneDeep } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import closeDialogAction from '../../../../actions/closeDialogAction';
import rpcAction from '../../../../actions/rpcAction';
import updateValidationErrorsAction from '../../../../actions/updateValidationErrorsAction';
import updateGlobalAppStateValueAction from '../../../../actions/updateGlobalAppStateValueAction';
import { altSkuType, nodeRoutes } from '../../../../constants';
import { persistentValues } from '@rtg/shared-react-components';
import * as storeUtilities from '../../../../utilities/storeUtilities';
import * as orderUtilities from '../OrderProductScreenUtilities';
import SingleSkuAltSkuDialog from './SingleSkuAltSkuDialog';
class SingleSkuAltSkuDialogContainer extends Component {
	render() {
		return (
			<SingleSkuAltSkuDialog {...this.props} />
		);
	}
}
const mapStateToProps = (state, props) => {
	const validationErrorsPath = [...props.path, 'altSkuTextBox'];
	const validationErrors = storeUtilities.getValidationErrors(validationErrorsPath);
	const altSkuIsInvalid = validationErrors !== undefined && validationErrors.errors !== undefined && Array.isArray(validationErrors.errors) && validationErrors.errors.length > 0;
	const altOrderLine = storeUtilities.getValue([...props.path, 'altOrderLine'], undefined);
	const substituteChecked = storeUtilities.getValue([...props.path, 'chkSubstitute'], false);
	const combinationChecked = storeUtilities.getValue([...props.path, 'chkCombination'], false);
	const orderLine = cloneDeep(props.orderLine);
	const usePDMPieceCountInUse = storeUtilities.getValue([props.screenId, 'usePDMPieceCountInUse'], false);
	const chkCombinationTooltips = 	(usePDMPieceCountInUse === false && orderLine?.PieceCount !== altOrderLine?.PieceCount) 
								 ||	(usePDMPieceCountInUse === true && orderLine?.PDMPieceCount !== altOrderLine?.PDMPieceCount) 
		? ['WARNING! Piece Counts do NOT match'] 
		: undefined;
	return {
		altSkuIsValid: !altSkuIsInvalid,
		altOrderLine,
		substituteChecked,
		combinationChecked,
		orderLine,
		usePDMPieceCountInUse,
		chkCombinationTooltips
	};
};
const mapDispatchToProps = (dispatch, props) => {
	const whsNum = storeUtilities.getValue([props.screenId, 'whsNumInUse']);
	const skuAltSkuPersistentValuePath = cloneDeep(props.path);
	skuAltSkuPersistentValuePath.pop();
	const altSkuPath = [...props.path, 'altSku'];
	const altSkuTextboxPath = [...props.path, 'altSkuTextBox'];
	const sku = props?.orderLine?.SKU?.toString?props.orderLine.SKU.toString():props.orderLine.SKU;
	return {
		onLoad: () => {
			const altSku = orderUtilities.getDefaultAltSku(props.screenId, props.orderLine, skuAltSkuPersistentValuePath);
			if(altSku !== undefined){
				storeUtilities.updateValue(dispatch, altSkuTextboxPath, String(altSku), true);
			}
			else {
				storeUtilities.deleteValue(dispatch, altSkuTextboxPath);
			}
			
			//get original checkbox values and save them to the store so they can be restored if user clicks cancel
			const substituteChecked = storeUtilities.getValue([...props.path, 'chkSubstitute'], false);
			const combinationChecked = storeUtilities.getValue([...props.path, 'chkCombination'], false);

			storeUtilities.updateValue(dispatch, [...props.path, 'ORIG_chkSubstitute'], substituteChecked);
			storeUtilities.updateValue(dispatch, [...props.path, 'ORIG_chkCombination'], combinationChecked);
		},
		onAltSkuChanged: (newValue) => {
			const altSku = storeUtilities.getValue(altSkuPath, '');
			if(String(altSku) !== String(newValue)){
				storeUtilities.deleteValue(dispatch, [...props.path, 'altOrderLine']);
				storeUtilities.updateValue(dispatch, [...props.path, 'chkSubstitute'], false);
				storeUtilities.updateValue(dispatch, [...props.path, 'chkCombination'], false);
			}
		},
		onLoadSkuClicked: () => {
			const altSku = storeUtilities.getValue(altSkuTextboxPath, undefined);
			if(altSku === undefined)
				return;
		
			const useTotalDays = storeUtilities.getValue([props.screenId, 'cbUseTotalDaysInUse'], false);

			dispatch(rpcAction({
				args: {sku: altSku},
				nodeRoute: nodeRoutes.IFRServiceNET,
				endpoint: 'Products/Exists',
				method: 'GET',
				hideLoadingMaskOnComplete: false,
				callback: (response) => {
					if(response === true){
						const transitTime = storeUtilities.getValue([props.screenId, 'transitTime'], 0);
						const prodTime = storeUtilities.getValue([props.screenId, 'prodTime'], 0);
						const combineDCsSelected = storeUtilities.getValue([props.screenId, 'enableCombineWhsList'], false);
						const dateFormat = 'YYYY-MM-DD';
						const args = {
							skuList: [altSku],
							warehouseList: combineDCsSelected === true ? storeUtilities.getValue([props.screenId, 'combineWhsList'], [whsNum]) : [whsNum],
							combineWarehouse: combineDCsSelected,
							salesStart: moment(storeUtilities.getValue([props.screenId, 'beginSalesDate'])).format(dateFormat),
							salesEnd: moment(storeUtilities.getValue([props.screenId, 'endSalesDate'])).format(dateFormat),
							productionLeadDays: prodTime,
							transitLeadDays: transitTime,
							daysToNextOrder: storeUtilities.getValue([props.screenId, 'daysToNextOrder']),
							safetyDays: storeUtilities.getValue([props.screenId, 'daysToStock'], 0),
							orderMethod: storeUtilities.getValue([props.screenId, 'merchGroup']),
							marketAdjustment: storeUtilities.getValue([props.screenId, 'marketAdjustment'], 0),
							poAdjustment: storeUtilities.getValue([props.screenId, 'POAdjustment'], 0),
							includeIntraDivisionOrders: storeUtilities.getValue([props.screenId, 'intraOrder'], false),
							excludePromotionalAvailable: storeUtilities.getValue([props.screenId, 'showPromoAvail'], false),
							excludeDates: orderUtilities.getExcludedMomentRanges([props.screenId, 'excludedMomentRanges'], 'YYYY-MM-DD'),
							useTotalDays
						};
						dispatch(rpcAction({
							args,
							nodeRoute: nodeRoutes.IFRServiceNET,
							endpoint: '/Orders/suggested',
							method: 'POST',
							hideLoadingMaskOnComplete: true,
							callback: (data) => {
								if(Array.isArray(data) && data.length === 1)
								{
									const {orderLines} = orderUtilities.getOrderLinesFromJsonArrayResults(data);
									const altOrderLine = orderLines[0];
									storeUtilities.updateValue(dispatch, [...props.path, 'altOrderLine'], cloneDeep(altOrderLine));
								}
							}
						}));
					}
					else {
						dispatch(updateValidationErrorsAction(altSkuTextboxPath, ['SKU is invalid'], false));
						dispatch(updateGlobalAppStateValueAction(['showLoadingMask'], false));
						storeUtilities.deleteValue(dispatch, [...props.path, 'altOrderLine']);
						storeUtilities.updateValue(dispatch, [...props.path, 'chkSubstitute'], false);
						storeUtilities.updateValue(dispatch, [...props.path, 'chkCombination'], false);
					}
				}
			}));
		},
		onChkCombinationChanged: (value) => {
			const altOrderLine = storeUtilities.getValue([...props.path, 'altOrderLine'], undefined);
			let orderValue = props.orderLine.Order || 0;
			let ast = undefined;
			if(value === true){
				orderValue = orderUtilities.getAltComQty(props.orderLine, altOrderLine);
				ast = altSkuType.COMBINATION;
				storeUtilities.updateValue(dispatch, [...props.path, 'chkSubstitute'], false);
			}
			if(props.onSkuTypeChanged){
				props.onSkuTypeChanged(orderValue > 0 ? orderValue : 0, cloneDeep(props.orderLine), altOrderLine, ast);
			}
		},
		onChkSubstituteChanged: (value) => {
			const altOrderLine = storeUtilities.getValue([...props.path, 'altOrderLine'], undefined);
			let orderValue = props.orderLine.Order || 0;
			let ast = undefined;
			if(value === true){
				orderValue = orderUtilities.getAltSubQty(props.orderLine, altOrderLine);
				ast = altSkuType.SUBSTITUTION;
				storeUtilities.updateValue(dispatch, [...props.path, 'chkCombination'], false);
			}
			if(props.onSkuTypeChanged){
				props.onSkuTypeChanged(orderValue > 0 ? orderValue : 0, cloneDeep(props.orderLine), altOrderLine, ast);
			}
		},
		onOkClicked: () => {
			const altSku = storeUtilities.getValue(altSkuTextboxPath, undefined);
			const skuAltSkuObj = persistentValues.get(skuAltSkuPersistentValuePath);
			const skuAltSkuArray = Array.isArray(skuAltSkuObj) ? skuAltSkuObj : [];
			const skuAltSkuMap = new Map(skuAltSkuArray);
			if(sku){
				skuAltSkuMap.set(sku, altSku);
				persistentValues.set(skuAltSkuPersistentValuePath, [...skuAltSkuMap]);
			}
			/***
			 * -intentinally not calling props.onDialogClosed because order value is already updated when checkboxes are changed.
			 * -therefore, all that needed to be done was to save the new alt-sku to browser storage.
			 */
			dispatch(closeDialogAction());
		},
		onRemoveAltSkuClicked: () => {
			const skuAltSkuObj = persistentValues.get(skuAltSkuPersistentValuePath);
			const skuAltSkuArray = Array.isArray(skuAltSkuObj) ? skuAltSkuObj : [];
			const skuAltSkuMap = new Map(skuAltSkuArray);
			skuAltSkuMap.delete(sku);
			persistentValues.set(skuAltSkuPersistentValuePath, [...skuAltSkuMap]);
			storeUtilities.deleteValue(dispatch, altSkuTextboxPath);
			storeUtilities.deleteValue(dispatch, [...props.path, 'altOrderLine']);
			storeUtilities.updateValue(dispatch, [...props.path, 'chkSubstitute'], false);
			storeUtilities.updateValue(dispatch, [...props.path, 'chkCombination'], false);

			const orderLine = cloneDeep(props.originalOrderLine);
			if(props.onDialogClosed){
				props.onDialogClosed(orderLine.Order, orderLine);
			}
			dispatch(closeDialogAction());
		},
		onCancelClicked: () => {
			const substituteChecked = storeUtilities.getValue([...props.path, 'ORIG_chkSubstitute'], false);
			const combinationChecked = storeUtilities.getValue([...props.path, 'ORIG_chkCombination'], false);
			const altOrderLine = storeUtilities.getValue([...props.path, 'altOrderLine'], undefined);
			storeUtilities.updateValue(dispatch, [...props.path, 'chkSubstitute'], substituteChecked);
			storeUtilities.updateValue(dispatch, [...props.path, 'chkCombination'], combinationChecked);
			let ast = undefined;
			if(substituteChecked){
				ast = altSkuType.SUBSTITUTION;
			}
			else if(combinationChecked){
				ast = altSkuType.COMBINATION;
			}
			const orderLine = cloneDeep(props.orderLine);
			const originalOrderLine = cloneDeep(props.originalOrderLine);
			if(props.onDialogClosed){
				if(substituteChecked === false && combinationChecked === false){
					props.onDialogClosed(originalOrderLine.Order, originalOrderLine);//restore the original orderLine from the store...if it exists
				}
				else { //all other cases: substituteChecked === true || combinationChecked === true
					props.onDialogClosed(orderLine.Order, orderLine, altOrderLine, ast);//restore the modified orderLine
				}
			}
			dispatch(closeDialogAction());
		}
	};
};
SingleSkuAltSkuDialogContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	parentPanelId: PropTypes.string,
	altOrderLine: PropTypes.object,
	orderLine: PropTypes.object.isRequired,
	altSkuIsValid: PropTypes.bool,
	height: PropTypes.number,
	onSkuTypeChanged: PropTypes.func,
	onLoad: PropTypes.func,
	onDialogClosed: PropTypes.func,
	path: PropTypes.array.isRequired,
	onChkCombinationChanged: PropTypes.func,
	onChkSubstituteChanged: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SingleSkuAltSkuDialogContainer);

export default connectedComponent;