import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { keyCodes } from '../../../constants';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import { 
	DataGridContainer,
	TextBoxContainer
} from '@rtg/shared-react-components';
import vendorClearanceOrderGridColumnDefs from './VendorClearanceOrderGridColumnDefs';
import store from '../../../configureStore';

class VendorClearanceOrderGridPanel extends Component {
	constructor(props){
		super(props);
		this.MAX_ROWS_WITHOUT_SCROLL = 10;
	}
	onModelUpdated = (params) => {
		if(this.props.onModelUpdated){
			this.props.onModelUpdated(params.api);
		}
	};
	onDeleteRowClicked = (node) => {
		if(this.props.onDeleteRowClicked){
			this.props.onDeleteRowClicked(node);
		}
	};
	getColumnValue = (params) => {
		if(this.props.getColumnValue){
			return this.props.getColumnValue(params);
		}
	};
	getFormattedColumnValue = (params) => {
		if(this.props.getFormattedColumnValue){
			return this.props.getFormattedColumnValue(params);
		}
	};
	getPanelId = () => {
		return this.props.panelId;
	};
	onValueChanged = (params) => {
		if(this.props.onValueChanged){
			this.props.onValueChanged(params);
		}
	};
	onEnterPressed = (params) => {
		if(this.props.onEnterPressed){
			this.props.onEnterPressed(params);
		}
	};
	getIsInvalid = (params, isRenderer) => {
		if(this.props.getIsInvalid){
			return this.props.getIsInvalid(params, isRenderer);
		}
	};
	getTooltips = (params, isRenderer) => {
		if(this.props.getTooltips){
			return this.props.getTooltips(params, isRenderer);
		}
	};
	cubesOverLimit = () => {
		return this.props.cubesOverLimit ? 'Cubes over limit' : undefined;
	};
	onCubesLimitChanged = () => {
		if(this.props.onCubesLimitChanged){
			this.props.onCubesLimitChanged();
		}
	};
	onGridReady = (params) => {
		params.api.sizeColumnsToFit();
	};
	render() {
		const rowCount = sharedUtilities.getValueFromObject(this.props, ['rowData', 'length']);//this.props.rowData.length); 
		const rowHeight = rowCount > this.MAX_ROWS_WITHOUT_SCROLL ? ((30 * (this.MAX_ROWS_WITHOUT_SCROLL + 2)) + 'px') : ((30 * (rowCount + 2)) + 'px');
		return (				
			<Container style={this.props.panelStyle} fluid={true}>
				<Card>
					<Card.Header>
						<Row>
							<Col xs={8} sm={8} md={8} lg={8} xl={8}>
								<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Vendor: {this.props.vendorCode}</h5>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<TextBoxContainer
									screenId={this.props.screenId}
									panelId={this.getPanelId()}
									fieldId={'cubesTotal'}
									overridePath={[this.props.screenId, this.getPanelId(), 'cubesTotal']}
									label={'Order Cubes'}
									mainComponentBootstrapSize={8}
									type={'number'}
									validators={[this.cubesOverLimit]}
									disabled 
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={2} lg={2} xl={2}>
								<TextBoxContainer
									screenId={this.props.screenId}
									panelId={this.getPanelId()}
									fieldId={'cubesLimit'} 
									overridePath={[this.props.screenId, this.getPanelId(), 'cubesLimit']}
									label={'Cubes Limit'}
									mainComponentBootstrapSize={8}
									type={'number'}
									persistValue
									onChange={this.onCubesLimitChanged}
									store={store}
								/>
							</Col>
						</Row>
					</Card.Header>
					<div style={{height:rowHeight}}>
						<DataGridContainer
							fieldId={''} 
							panelId={this.getPanelId()}
							screenId={this.props.screenId} 
							defaultColDef={{
								resizable: true, 
								sortable: true,
								suppressKeyboardEvent: (params)=>{ 
									return params.event.type === 'keydown' && params.event.which === keyCodes.ENTER;
								}
							}}
							rowSelection={'multiple'}
							forwardRef={this.props.gridRef}
							columnDefs={vendorClearanceOrderGridColumnDefs(
								this.props.screenId, 
								'panel_' + this.props.vendorCode, 
								'clearanceGrid_' + this.props.index, 
								this.getColumnValue,
								this.onValueChanged,
								this.onEnterPressed,
								this.getIsInvalid,
								this.getTooltips, 
								this.onDeleteRowClicked,
								this.getFormattedColumnValue,
								this.props.selectedSalesRange.name)}
							rowData={this.props.rowData}
							sizeColumnsToFit
							onModelUpdated={this.onModelUpdated}
							store={store}
							rowHeight={30}
							onGridReady={this.onGridReady}
						/>
					</div>
				</Card>
			</Container>);
	}
}

VendorClearanceOrderGridPanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	rowData: PropTypes.array,
	vendorCode: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	panelStyle: PropTypes.object,
	getColumnValue: PropTypes.func,
	getFormattedColumnValue: PropTypes.func,
	onValueChanged: PropTypes.func,
	onEnterPressed: PropTypes.func,
	getIsInvalid: PropTypes.func,
	getTooltips: PropTypes.func,
	onDeleteRowClicked: PropTypes.func,
	onModelUpdated: PropTypes.func,
	cubesOverLimit: PropTypes.bool,
	onCubesLimitChanged: PropTypes.func
};

export default VendorClearanceOrderGridPanel;
