import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import stringifyObject from 'stringify-object';
import showDialogAction from '../../../actions/showDialogAction';
import updateDefaultValuesAction from '../../../actions/updateDefaultValuesAction';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer';
import SearchPartsListResultsPanel from './SearchPartsListResultsPanel';
class SearchPartsListResultsPanelContainer extends Component {
	render() {
		return (
			<SearchPartsListResultsPanel {...this.props}/>
		);
	}
}

const mapStateToProps = (state, props) => {
	const rowData = storeUtilities.getValue([props.screenId, 'PartSearchResultsGrid', 'rowData'], undefined);
	const selectedRows = storeUtilities.getValue([props.screenId, 'PartSearchResultsGrid', 'selectedRows']);
	const whsList = sharedUtilities.getWhsList();
	const vendorEmailInfos = storeUtilities.getValue([props.screenId, 'VendorEmailInfos'], undefined);
	const divisionsInUse = storeUtilities.getValue([props.screenId, 'DivisionsInUse'], undefined);
	const vendorCodeInUse = storeUtilities.getValue([props.screenId, 'VendorCodeInUse'], undefined);
	const submitButtonDisabled = whsList === undefined
	|| divisionsInUse === undefined
	|| vendorCodeInUse === undefined
	|| vendorEmailInfos === undefined
	|| !selectedRows 
	|| !Array.isArray(selectedRows) 
	|| selectedRows.length <= 0;
	const submitButtonTooltips = getSubmitButtonTooltips(props.screenId, submitButtonDisabled);
	return {
		selectAllButtonDisabled: ((selectedRows && Array.isArray(selectedRows) && selectedRows.length === rowData.length) || rowData === undefined || rowData.length === 0),
		deselectAllButtonDisabled: !selectedRows || !Array.isArray(selectedRows) || selectedRows.length === 0,
		submitButtonDisabled,
		rowData: rowData !== undefined && Array.isArray(rowData) ? rowData : [],
		submitButtonTooltips
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			dispatch(updateDefaultValuesAction([props.screenId], props.defaultValues));
		},
		handleSubmitClicked: (selectedRows) => {
			if(selectedRows.length === 0){
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						screenId={props.screenId + '_errorDialog'} 
						title={'ERROR'}
						height={250}
						width={400}>
							Please select one or more SKUs to continue.
					</OkCancelDialogContainer>, 
					250, 
					400, 
					()=>{}));
			}
			else if (selectedRows.length > 150){
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						screenId={props.screenId + '_warningDialog'} 
						title={'WARNING'}
						handleOkClicked={()=>{ continueNavigation(props.history, props.screenId, selectedRows, dispatch); }}
						height={250}
						width={400}
						hasCancelButton>
							You have selected over 150 SKUs. This may cause extensive delays and/or failure in calculating your replensihment requirements. Would you like to continue?
					</OkCancelDialogContainer>, 
					250, 
					400, 
					()=>{}));
			}
			else {
				updateSelectedRowsInStore(props.screenId, selectedRows, dispatch);
				continueNavigation(props.history, props.screenId, selectedRows, dispatch);
			}
		},
		handleRowClicked: (selectedRows) => {
			updateSelectedRowsInStore(props.screenId, selectedRows, dispatch);
		},
		handleSelectAllClicked: (selectedRows) => {
			updateSelectedRowsInStore(props.screenId, selectedRows, dispatch);
		},
		handleDeselectAllClicked: (selectedRows) => {
			updateSelectedRowsInStore(props.screenId, selectedRows, dispatch);
		},
		onGridReady: (obj) => {
			obj.api.sizeColumnsToFit();
			storeUtilities.updateValue(dispatch, [props.screenId, 'sizePartSearchResultsGridColumnsToFit'], () => {
				obj.api.sizeColumnsToFit(); 
			});
		},
		onSubmitButtonMouseOver: (submitButtonDisabled) => {
			if(submitButtonDisabled === true){
				const selectedRows = storeUtilities.getValue([props.screenId, 'PartSearchResultsGrid', 'selectedRows']);
				const whsList = sharedUtilities.getWhsList();
				const vendorEmailInfos = storeUtilities.getValue([props.screenId, 'VendorEmailInfos'], undefined);
				const validationErrors = storeUtilities.getValidationErrors([props.screenId]);
				const reason = 'Submit button is disabled because ';
				let includeBreak = false;
				if(whsList === undefined){
					console.log(reason + 'whsList is undefined');
					includeBreak = true;
				}
				if('Region' in validationErrors){
					console.log(reason + 'selected Region is invalid');
					includeBreak = true;
				}
				if('VendorCode' in validationErrors){
					console.log(reason + 'selected VendorCode is invalid');
					includeBreak = true;
				}
				if(vendorEmailInfos === undefined){
					console.log(reason + 'vendorEmailInfos is undefined');
					includeBreak = true;
				}
				if(!Array.isArray(selectedRows) || selectedRows.length <= 0){
					console.log(reason + 'no rows are selected');
					includeBreak = true;
				}
				if(includeBreak){
					console.log('============================================');
				}
			}
			else {
				console.log('Submit button is enabled');
			}
		},
		getIsXCellHidden(cellProps){
			switch(cellProps.colDef.colId){
				case 'discontinuedSku':
				case 'inactiveSku':
				case 'toBeDropped':
					return cellProps?.node?.data ? cellProps.node.data[cellProps.colDef.colId] === 'N' : true;
				case 'vendorMismatch':
					return cellProps?.node?.data ? cellProps.node.data[cellProps.colDef.colId] === 0 : true;
				default: 
					return false;
			}
		}
	};
};
const getSubmitButtonTooltips = (screenId, submitButtonDisabled) => {
	if(submitButtonDisabled === true){
		const rowData = storeUtilities.getValue([screenId, 'PartSearchResultsGrid', 'rowData'], []);
		const selectedRows = storeUtilities.getValue([screenId, 'PartsSearchResultsGrid', 'selectedRows']);
		const whsList = sharedUtilities.getWhsList();
		const vendorEmailInfos = storeUtilities.getValue([screenId, 'VendorEmailInfos'], undefined);
		const validationErrors = storeUtilities.getValidationErrors([screenId]);
		const vendorCode = storeUtilities.getValue([screenId, 'VendorCodeInUse'], undefined);
		//check specificially against the 'VendorCodeInUse'...not 'VendorCode' or 'poVendorCode'
		const vendorHasValidVendorEmailInfos = sharedUtilities.vendorHasValidVendorEmailInfos(vendorCode, vendorEmailInfos);
		
		const tooltips = [];
		if('Region' in validationErrors){
			tooltips.push('Division is required');
		}
		if('VendorCode' in validationErrors){
			tooltips.push('Vendor Code is required');
		}
		if(rowData.length > 0 && (!Array.isArray(selectedRows) || selectedRows.length <= 0)){
			tooltips.push('You must select at least 1 row to continue');
		}
		if(whsList === undefined){
			return ['Application error. Please refresh browser.'];
		}
		if(rowData.length === 0 || vendorEmailInfos === undefined || vendorHasValidVendorEmailInfos === false){
			return ['Click Search to continue'];
		}
		return tooltips;
	}
	else {
		return undefined;
	}
};
const updateSelectedRowsInStore = (screenId, selectedRows, dispatch) => {
	storeUtilities.updateValue(dispatch, [screenId , 'PartSearchResultsGrid', 'selectedRows'], selectedRows);
};
const continueNavigation = (history, screenId, selectedRows, dispatch) => {
	const vendor = storeUtilities.getValue([screenId, 'VendorCodeInUse'], undefined);
	const divisions = storeUtilities.getValue([screenId, 'DivisionsInUse'], undefined);
	const vendorEmailInfos = storeUtilities.getValue([screenId, 'VendorEmailInfos'], undefined);
	let simplePOList = '';
	if (storeUtilities.getValue([screenId, 'poList'])){
		simplePOList = storeUtilities.getValue([screenId, 'poList']).replace(/ /g,'');
	} 
	const polist = storeUtilities.getValue([screenId, 'POSearchResultsGrid', 'selectedRows']);
	const search = sharedUtilities.getValueFromObject(history, ['location', 'search']) || '';
	/* We then set the selected rows as initialization state for the screen we are routing to*/
	const initializationData = {
		vendor,
		divisions,
		vendorEmailInfos,
		polist,
		simplePOList,
		selectedRows
	};
	window.console.log('not urlEncoded\n #initializationData=' + stringifyObject(initializationData));
	dispatch(push('/orderparts' + search, {
		divisions,
		vendor,
		selectedRows,
		vendorEmailInfos,
		polist,
		simplePOList
	}));
};

SearchPartsListResultsPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	handleSearchClicked: PropTypes.func,
	searchButtonDisabled: PropTypes.bool,
	onGridReady: PropTypes.func,
	history: PropTypes.any,
	onLoad: PropTypes.func,
	onSubmitButtonMouseOver: PropTypes.func,
	submitButtonTooltips: PropTypes.array,
	getIsXCellHidden: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SearchPartsListResultsPanelContainer);

connectedComponent.defaultProps = {};

export default connectedComponent;