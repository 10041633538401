import PropTypes from 'prop-types';
import React, { Component } from 'react';
import OkCancelDialogContainer from '../../../dialogs/OkCancelDialogContainer';
import { 
	DataGridContainer,
	LabelContainer
} from '@rtg/shared-react-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SizeMe } from 'react-sizeme';
import * as numberUtilities from '../../../../utilities/numberUtilities';
import * as sharedUtilities from '../../../../utilities/sharedUtilities';
import store from '../../../../configureStore';
const PANEL_ID = 'OnOrderDialog';
class OnOrderDialog extends Component { 
	constructor(props){
		super(props);
		this.compareValues = this.compareValues.bind(this);
	}
	onGridReady(params){
		if(this.props.onGridReady){
			this.props.onGridReady(params);
		}
	}
	onModelUpdated(params){
		if(this.props.onModelUpdated){
			this.props.onModelUpdated(params);
		}
	}
	getColumnValue(params){
		if(this.props.getColumnValue){
			return this.props.getColumnValue(params);
		}
	}
	getFormattedValue(params){
		if(this.props.getFormattedValue){
			return this.props.getFormattedValue(params);
		}
	}
	compareValues(colId, value1, value2, node1, node2){
		if(this.props.compareValues){
			return this.props.compareValues(colId, value1, value2, node1, node2);
		}
	}
	render() {
		return (
			<OkCancelDialogContainer
				screenId={this.props.screenId} 
				title={'On Order (' + this.props.vendor + ')'}
				handleOkClicked={()=>{}}
				style={{paddingLeft: '0px', paddingRight: '0px'}}>
				<SizeMe monitorHeight={true} className={'h-100'}> 
					{({size}) => {
						const height = numberUtilities.getNumberOrDefault(size.height, 50);
						return (
							<Container fluid={true} className={'h-100 m-0 p-0'} style={{overflow:'hidden'}}>
								<Row style={{height: (height - 30) + 'px'}}>
									<Col>
										<DataGridContainer
											fieldId={'OnOrderGrid'} 
											panelId={PANEL_ID}
											screenId={this.props.screenId} 
											defaultColDef={{resizable:true, filter:true}}
											columnDefs={[
												{
													headerName: 'Division',
													field: 'division',
													colId: 'division',
													sortable: true,
													width: 30,
													minWidth: 30
												},
												{
													headerName: 'Warehouse',
													field: 'whs',
													colId: 'whs',
													sortable: true,
													width: 40,
													minWidth: 40,
													valueGetter: this.getColumnValue.bind(this),
													comparator: (value1, value2, node1, node2, isInverted) => { 
														return this.compareValues('whs', value1, value2, node1, node2, isInverted); 
													}
												},
												{
													headerName: 'Month',
													field: 'month',
													colId: 'month',
													sortable: true,
													width: 40,
													minWidth: 40,
													comparator: (value1, value2, node1, node2, isInverted) => { 
														return this.compareValues('month', value1, value2, node1, node2, isInverted); 
													}
												},
												{
													headerName: 'Year',
													field: 'year',
													colId: 'year',
													sortable: true,
													width: 40,
													minWidth: 40
												},
												{
													headerName: 'Amount',
													field: 'onOrderAmount',
													colId: 'onOrderAmount',
													sortable: true,
													width: 50,
													minWidth: 50,
													type: 'numericColumn',
													valueFormatter: this.getFormattedValue.bind(this)
												},
												{
													headerName: '',
													field: '',
													colId: '',
													sortable: false,
													width: 20,
													minWidth: 20
												}
											]}
											onGridReady={this.onGridReady.bind(this)}
											onModelUpdated={this.onModelUpdated.bind(this)}
											rowData={this.props.rowData}
											store={store}
										/>
									</Col>
								</Row>
								<Row style={{height:'30px'}}>
									<Col xs={6} sm={6} md={6} lg={6} xl={6}
									>&nbsp;</Col>
									<Col xs={3} sm={3} md={3} lg={3} xl={3}>
										<LabelContainer
											screenId={this.props.screenId}
											panelId={PANEL_ID}
											fieldId={'onOrderTotalLabel'}
											value={'Total: '}
											style={{fontSize: '16px'}}
											store={store}
										/>
									</Col>
									<Col xs={3} sm={3} md={3} lg={3} xl={3}>
										<div className={'pr-5'}>
											<LabelContainer
												screenId={this.props.screenId}
												panelId={PANEL_ID}
												fieldId={'onOrderTotal'}
												overridePath={[this.props.screenId, 'OnOrderGrid', 'onOrderTotal']}
												type={'number'}
												useStore={true}
												style={{fontSize: '16px',fontWeight:'bold'}}
												valueFormatter={(value) => {
													return sharedUtilities.formatCurrency(value);
												}}
												store={store}
											/>
										</div>
									</Col>
								</Row>
							</Container>
						);}}
				</SizeMe>
			</OkCancelDialogContainer>
		);
	}
}
OnOrderDialog.propTypes = {
	screenId: PropTypes.string.isRequired,
	rowData: PropTypes.any,
	getColumnValue: PropTypes.func,
	getFormattedValue: PropTypes.func,
	compareValues: PropTypes.func,
	onModelUpdated: PropTypes.func,
	compareOnOrderAmounts: PropTypes.func,
	vendor: PropTypes.string.isRequired,
	onGridReady: PropTypes.func
};

export default OnOrderDialog;
