import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import * as validators from '../../../utilities/commonValidators';
import CheckBoxContainer from '../../formelements/CheckBoxContainer';
import HiddenFieldContainer from '../../formelements/HiddenFieldContainer';
import * as numberUtilities from '../../../utilities/numberUtilities';
import SalesHistoryGroupBoxContainer from './SalesHistoryGroupBoxContainer';
import withID from '../../../higherordercomponents/WithID';
import {
	ButtonContainer,
	DatePickerContainer,
	DropdownContainer,
	LabelContainer,
	MultiSelectDropdownContainer,
	TextAreaContainer,
	TextBoxContainer
} from '@rtg/shared-react-components';
import store from '../../../configureStore';
import '../../../css/DatePickerOverrides.css';
class MerchandisePanel extends Component {
	componentDidMount(){
		if(this.props.onLoad){
			this.props.onLoad(()=> {
				this.calculateTotalDaysAndLeadDate();
			});
		}
	}
	goBack = () => {
		if(this.props.goBack){
			this.props.goBack();
		}
	};
	calculateTotalDaysAndLeadDate = () => {
		if(this.props.calculateTotalDaysAndLeadDate){
			this.props.calculateTotalDaysAndLeadDate();
		}
	};
	handleWarehouseChanged = () => {
		if(this.props.handleWarehouseChanged){
			this.props.handleWarehouseChanged();
		}
	};
	onGetSuggestedOrdersClicked = () => {
		if(this.props.onGetSuggestedOrdersClicked){
			this.props.onGetSuggestedOrdersClicked();
		}
	};
	handleUseTotalDaysChanged = (value) => {
		if(this.props.calculateTotalDaysAndLeadDate){
			this.props.calculateTotalDaysAndLeadDate(value);
		}
	};
	handleCategoryChanged = (value) => {
		if(this.props.handleCategoryChanged){
			this.props.handleCategoryChanged(value);
		}
	};
	handleOrderDateChanged = () => {
		this.calculateTotalDaysAndLeadDate();
	};
	validateProdAndTransTime = (args, otherValue, otherFieldId, isInitialValidationCheck) => {
		const value1 = numberUtilities.getNumberOrDefault(args.value, 0);
		const value2 = numberUtilities.getNumberOrDefault(otherValue, 0);
		const isValid = (value1 + value2) > 0;
		const msg = 'Sum of Prod Lead Time and Transit Lead Time must be greater than 0';
		if(this.props.updateProdAndTransTimeValidationError){
			this.props.updateProdAndTransTimeValidationError(isValid, msg, otherFieldId, isInitialValidationCheck);
		}
		return isValid === true ? undefined : msg;
	};
	useLoadFactorChanged = (value) => {
		if(this.props.useLoadFactorChanged){
			this.props.useLoadFactorChanged(value);
		}
	};
	render() {
		return (
			<Container className={'h-100 m-0 p-0'} id={this.props.id} data-testid={this.props.id}>
				<Card className={'h-100'}>
					<Card.Header>
						<Row className={'p-0 m-0'} noGutters={true}>
							<Col xs={12} sm={12} md={12} lg={6} xl={6}>
								<h5 className='text-center text-sm-center text-md-center text-lg-left text-xl-left pr-2'>Merchandise</h5>
							</Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none' />
							<Col xs={8} sm={8} md={6} lg={6} xl={6} className={'pr-1 mb-1'}>
								<ButtonContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'btnReturnToSearch'} 
									onClick={this.goBack} 
									text={'Return to Search'}
									fill
									store={store}
								/>
							</Col>
							<Col xs={2} sm={2} md={3} className='d-lg-none d-xl-none'/>
						</Row>
					</Card.Header>
					<Card.Body>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'vendor'}
									label={'Vendor'}
									mainComponentBootstrapSize={7}
									disabled
									store={store} 
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'useLoadFactor'}
									label={'Use Load Factor'}
									mainComponentBootstrapSize={1}
									style={{right: '-10px'}}
									onChange={this.useLoadFactorChanged}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<CheckBoxContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'usePDMPieceCount'}
									label={'Use PDM "# of Skus in Carton"'}
									mainComponentBootstrapSize={1}
									style={{right: '-10px'}}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'txtBuyerInitials'} 
									label={'Buyer Initials'}
									validators={[validators.required]}
									showRequiredAsterisk
									mainComponentBootstrapSize={7}
									persistValue
									trimValue
									store={store} 
								/>
							</Col>
						</Row>
						<Row className={'mb-1 d-none'}>
							<Col>
								<DatePickerContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'orderDate'}
									onChange={this.handleOrderDateChanged}
									label={'Date'}
									mainComponentBootstrapSize={7}
									disabled
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<DropdownContainer
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'merchGroup'}
									options={this.props.categories}
									hasBlankOption
									label={'Category'}
									validators={[validators.required]}
									showRequiredAsterisk
									onChange={this.handleCategoryChanged}
									store={store}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<DropdownContainer 
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'whsNum'}
									onChange={this.handleWarehouseChanged}
									label={'Ship-To Warehouse'}
									validators={[validators.required]}
									options={this.props.whsOptions}
									showRequiredAsterisk
									hasBlankOption
									disabled={this.props.whsIsSavannah}
									store={store}
								/>
							</Col>
						</Row>
						<Row noGutters={true} className={'mb-1'}>
							<Col xs={4} sm={4} md={4} lg={4} xl={4}>
								<LabelContainer
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'combineWhsListLabel'} 
									value={'Combine DCs'}
									showRequiredAsterisk={this.props.disableCombineWhsList === false}
									store={store}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1}>
								<CheckBoxContainer
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'enableCombineWhsList'} 
									disabled={this.props.disableCombineWhsListCheckbox}
								/>
							</Col>
							<Col xs={7} sm={7} md={7} lg={7} xl={7}>
								<MultiSelectDropdownContainer
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'combineWhsList'} 
									options={this.props.whsOptions}
									disabled={this.props.disableCombineWhsList}
									validators={[validators.required]}
									store={store}
								/>
							</Col>
						</Row>
						{(this.props.isPillows === undefined || this.props.isPillows === true) &&
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'maxPO'}
									type={'number'}
									label={'Max PO Quantity'}
									validators={[validators.required]}
									showRequiredAsterisk
									persistValue
									maxCharacters={10}
									mainComponentBootstrapSize={7}
									dependentFieldValue={this.props.maxPerWeek}
									store={store} 
								/>
							</Col>
						</Row>
						}
						{(this.props.isPillows === undefined || this.props.isPillows === false) &&
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'poCube'}
									type={'number'}
									label={'PO Cube'}
									validators={[validators.required]}
									showRequiredAsterisk
									persistValue
									maxCharacters={10}
									store={store} 
								/>
							</Col>
						</Row>
						}
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer
									mainComponentBootstrapSize={7}
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'maxPerWeek'}
									type={'number'}
									label={this.props.isPillows ? 'Max QTY/Week' : 'Max Containers/Week'}
									defaultTooltip={'0 = No Limit'}
									maxCharacters={10}
									store={store} 
								/>
							</Col>
						</Row>
						{( this.props.featureEnableBulkOrdering ) && (
							<Row noGutters={true} className={'mb-1'}>
								<Col xs={4} sm={4} md={4} lg={4} xl={4}>
									<LabelContainer
										screenId={this.props.screenId} 
										panelId={this.props.panelId}
										fieldId={'bulkOrderingLabel'} 
										value={'Bulk Ordering'}
										store={store}
									/>
								</Col>
								<Col xs={1} sm={1} md={1} lg={1} xl={1}>
									<CheckBoxContainer
										screenId={this.props.screenId} 
										panelId={this.props.panelId}
										fieldId={'enableBulkOrdering'} 
									/>
								</Col>
								<Col xs={7} sm={7} md={7} lg={7} xl={7}>
									{( this.props.bulkOrderingDateList?.length === 0 && !this.props.disableBulkOrdering) ? (
										<span className='text text-muted small'>{this.props.bulkOrderingNoCuttingDatesMessage}</span>
									) : (
									
										<DropdownContainer
											mainComponentBootstrapSize={7}
											screenId={this.props.screenId} 
											panelId={this.props.panelId}
											fieldId={'bulkOrdering'} 
											options={this.props.bulkOrderingDateList}
											disabled={this.props.disableBulkOrdering}
											store={store}
										/>
									)}
								</Col>
							</Row>
						)}
						<Row className={'mb-2 mt-3'}>
							<Col>
								<SalesHistoryGroupBoxContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}

									startDateFieldId={'beginSalesDate'}
									startDatePath={[this.props.screenId, 'beginSalesDate']}
									startDateLabel={'Begin'}
									startDatePersist

									endDateFieldId={'endSalesDate'}
									endDatePath={[this.props.screenId, 'endSalesDate']}
									endDateLabel={'End'}
									endDatePersist

									excludeDatesButtonFieldId={'excludeDatesLink'}
									excludeDatesButtonText={'Exclude Dates' + ((Array.isArray(this.props.excludedDatesTooltips) && this.props.excludedDatesTooltips.length > 0) ? '*' : '')}
									excludeDatesButtonTooltips={this.props.excludedDatesTooltips}

									totalDaysTextBoxFieldId={'salesDays'}
									totalDaysTextBoxPath={[this.props.screenId, 'salesDays']}
									totalDaysTextBoxLabel={'Total Days'}

									excludedMomentRangesPath={[this.props.screenId, 'excludedMomentRanges']}
									hasExcludeDates
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'prodTime'} 
									type={'number'}
									onChange={this.calculateTotalDaysAndLeadDate}
									label={'Prod Lead Time'}
									validators={[validators.required, (args, isInitialValidationCheck) => this.validateProdAndTransTime(args, this.props.transitTime, 'transitTime', isInitialValidationCheck)]}
									showRequiredAsterisk
									mainComponentBootstrapSize={3}
									maxCharacters={3}
									persistValue
									store={store} 
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'transitTime'} 
									type={'number'}
									onChange={this.calculateTotalDaysAndLeadDate}
									label={'Transit Lead Time'}
									validators={[validators.required, (args, isInitialValidationCheck) => this.validateProdAndTransTime(args, this.props.prodTime, 'prodTime', isInitialValidationCheck)]}
									showRequiredAsterisk
									mainComponentBootstrapSize={3}
									maxCharacters={3}
									persistValue
									disabled={this.props.transitTimeDisabled}
									store={store} 
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'daysToNextOrder'}
									type={'number'} 
									onChange={this.calculateTotalDaysAndLeadDate}
									label={'Days to Next Order'}
									mainComponentBootstrapSize={3}
									maxCharacters={3}
									persistValue
									store={store} 
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'daysToStock'}
									type={'number'} 
									onChange={this.calculateTotalDaysAndLeadDate}
									label={'Add\'l Days of Stock'}
									mainComponentBootstrapSize={3}
									maxCharacters={3}
									persistValue
									store={store} 
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'txtTotalDays'}
									type={'number'}
									label={'Total Days'} 
									disabled
									mainComponentBootstrapSize={3}
									store={store} 
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<CheckBoxContainer
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'cbUseTotalDays'} 
									disabled={this.props.disableCombineWhsListCheckbox}
									label={'Use Total Days'}
									mainComponentBootstrapSize={1}
									style={{right: '-10px'}}
									onChange={this.handleUseTotalDaysChanged}
								/>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<DatePickerContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'dtsCal'}
									disabled
									store={store}
								/>
							</Col>
						</Row>
						<hr />
						<Row className={'mb-1'}>
							<Col className={'mr-1 d-flex'}>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'marketAdjustment'}
									type={'number'} 
									label={'Sales Adj +/-'}
									mainComponentBootstrapSize={4}
									validators={[
										(args) => validators.inRange({...args, lowerLimit:-100, upperLimit:100, lowerLimitInclusive:true, upperLimitInclusive:true})
									]}
									store={store} 
								/>
								<span style={{position:'relative', left: '2px', top: '5px'}}>
								%
								</span>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col className={'mr-1 d-flex'}>
								<TextBoxContainer 
									screenId={this.props.screenId} 
									panelId={this.props.panelId}
									fieldId={'POAdjustment'}
									type={'number'} 
									label={'PO Adj +/-'}
									mainComponentBootstrapSize={4}
									validators={[
										(args) => validators.inRange({...args, lowerLimit:-100, upperLimit:100, lowerLimitInclusive:true, upperLimitInclusive:true})
									]}
									store={store} 
								/>
								<span style={{position:'relative', left: '2px', top: '5px'}}>
								%
								</span>
							</Col>
						</Row>
						<Row className={'mb-1'}>
							<Col>
								<CheckBoxContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'intraOrder'}
									label={'Include Intradivisional Orders'}
									mainComponentBootstrapSize={1}
									style={{right: '-10px'}}
								/>
							</Col>
						</Row>
						<Row className={'mb-3'}>
							<Col xs={12} sm={12} md={12} lg={12} xl={12}>
								<CheckBoxContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'showPromoAvail'}
									label={'Exclude Promotional Available'}
									mainComponentBootstrapSize={1}
									style={{right: '-10px'}}
								/>
							</Col>
						</Row>
						<Row className={'mb-1 ml-0 mr-0'}>
							<Col xs={1} sm={1} md={1} lg={1} xl={1}/>
							<Col xs={10} sm={10} md={10} lg={9} xl={9}>
								<ButtonContainer
									screenId={this.props.screenId}	
									panelId={this.props.panelId}
									fieldId={'btnGetSuggestedOrders'} 
									onClick={this.onGetSuggestedOrdersClicked} 
									text={'Get Suggested Orders'} 
									disabled={this.props.disableGetSuggestedOrdersButton === true}
									fill
									store={store}
								/>
							</Col>
							<Col xs={1} sm={1} md={1} lg={1} xl={1}/>
						</Row>
						<hr />
						<Row className={'mb-3 align-items-end'}>
							<Col xs={3} sm={3} md={3} lg={6} xl={6}>
								<LabelContainer
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'numOfSelectedSKUsLabel'}
									value={'Number of Selected SKUs: '} 
									store={store}
								/>
							</Col>
							<Col xs={3} sm={3} md={3} lg={3} xl={3}>
								<LabelContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'numOfSelectedSKUsCountLabel'}
									useStore
									store={store}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<TextAreaContainer 
									screenId={this.props.screenId}
									panelId={this.props.panelId}
									fieldId={'selectedSkusTextArea'}
									label={'Selected SKUs: '}
									height={'55px'}
									mainComponentBootstrapSize={9}
									disabled
									store={store}
								/>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				{/* the api expects the list of skus to have no spaces, so we must strip out all of the spaces */}
				<HiddenFieldContainer 
					screenId={this.props.screenId} 
					panelId={this.props.panelId}
					fieldId={'skuList'}
				/>
			</Container>
		);
	}
}

MerchandisePanel.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	goBack: PropTypes.func,
	calculateTotalDaysAndLeadDate: PropTypes.func,
	handleWarehouseChanged: PropTypes.func,
	isPillows: PropTypes.bool,
	skusList: PropTypes.string,
	onGetSuggestedOrdersClicked: PropTypes.func,
	productSearchSelectedRows: PropTypes.array,
	disableGetSuggestedOrdersButton: PropTypes.bool,
	onBeforeLoad: PropTypes.func,
	whsOptions: PropTypes.array,
	disableCombineWhsList: PropTypes.bool,
	disableCombineWhsListCheckbox: PropTypes.bool,
	handleCategoryChanged: PropTypes.func,
	prodTime: PropTypes.number,
	handleExcludeDatesClicked: PropTypes.func,
	transitTime: PropTypes.number,
	excludedDatesTooltips: PropTypes.array,	updateProdAndTransTimeValidationError: PropTypes.func,
	whsIsSavannah: PropTypes.bool,
	categories: PropTypes.arrayOf(PropTypes.object).isRequired,
	useLoadFactorChanged : PropTypes.func,
	onLoad: PropTypes.func,
	id: PropTypes.string,
	maxPerWeek: PropTypes.number,
	featureEnableBulkOrdering: PropTypes.any,
	bulkOrderingDateList: PropTypes.array,
	disableBulkOrdering: PropTypes.any,
	bulkOrderingNoCuttingDatesMessage: PropTypes.string,
	transitTimeDisabled: PropTypes.any
};

export default withID(MerchandisePanel);
