import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { persistentValues } from '@rtg/shared-react-components';
import * as storeUtilities from '../../utilities/storeUtilities';
import CheckBox from './CheckBox';

class CheckBoxContainer extends Component {
	constructor(props){
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(value){
		this.props.updateValue(value);
		if(this.props.onChange){
			this.props.onChange(value, this.props.fieldId);
		}
	}
	render() {
		return (
			<CheckBox
				screenId={this.props.screenId}
				panelId={this.props.panelId}
				fieldId={this.props.fieldId}
				label={this.props.label}
				name={this.props.name}
				disabled={this.props.disabled}
				onChange={this.handleChange}
				visible={this.props.visible}
				value={this.props.value}
				onLoad={this.props.onLoad}
				type={this.props.type}
				mainComponentBootstrapSize={this.props.mainComponentBootstrapSize}
				width={this.props.width}
				labelPosition={this.props.labelPosition}
				labelStyle={this.props.labelStyle}
				style={this.props.style}
				tooltips={this.props.tooltips}
			/>
		);
	}
}

export const mapStateToProps = (_state, props) => {
	const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
	const valueInStore = path !== undefined ? storeUtilities.getValue(path, undefined) : undefined;
	const tooltips = props.tooltips ? props.tooltips : [];
	return {
		value: valueInStore===true,
		visible: props.visible,
		tooltips
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
			const persistedValue = persistentValues.get(path);
			const persistValue = props.persistValue && persistedValue !== undefined && persistedValue.trim && persistedValue.trim() !== '';
			const valueInStore = path !== undefined ? storeUtilities.getValue(path, undefined) : undefined;
			const value = valueInStore !== undefined ? valueInStore : (persistValue === true ? persistedValue : props.value);
			storeUtilities.initializeValue(dispatch, path, value, persistValue);
		},
		updateValue: (value) => {
			const path = storeUtilities.getPath(props.screenId, props.fieldId, props.overridePath);
			storeUtilities.updateValue(dispatch, path, value, false, props.persistValue);
		}
	};
};

CheckBoxContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	fieldId: PropTypes.string.isRequired,
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	visible: PropTypes.bool,
	overridePath: PropTypes.array,
	type: PropTypes.string,
	width: PropTypes.string,
	mainComponentBootstrapSize: PropTypes.number,
	persistValue: PropTypes.bool,
	labelPosition: PropTypes.string,
	labelStyle: PropTypes.object,
	style: PropTypes.object,
	tooltips: PropTypes.arrayOf(PropTypes.string),
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(CheckBoxContainer);

connectedComponent.defaultProps = {
	visible: true
};

export default connectedComponent;