import PropTypes from 'prop-types';
import React, { Component } from 'react';
const withID = (WrappedComponent) => {
	class WithIDHOC extends Component {
		getId(){
			let id = this.props.screenId;
			if(this.props.panelId && this.props.panelId.trim && this.props.panelId.trim() !== ''){
				id += '_' + this.props.panelId;
			}
			if(this.props.fieldId && this.props.fieldId.trim && this.props.fieldId.trim() !== ''){
				id += '_' + this.props.fieldId;
			}
			return (id && id.trim && id.trim() === '') ? 'AUTO_GENERATED_ID_' + Math.random() : id;
		}
		render(){
			return <WrappedComponent id={this.getId()} {...this.props} />;
		}
	}
	WithIDHOC.propTypes = {
		screenId: PropTypes.string.isRequired,
		panelId: PropTypes.string, //optional
		fieldId: PropTypes.string, //optional
		...WrappedComponent.propTypes 
	};
	return WithIDHOC;
};
export default withID;