import { goBack } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import showDialogAction from '../../../actions/showDialogAction.jsx';
import updateValidationErrorsAction from '../../../actions/updateValidationErrorsAction.jsx';
import * as sharedUtilities from '../../../utilities/sharedUtilities.jsx';
import * as storeUtilities from '../../../utilities/storeUtilities.jsx';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer.jsx';
import MerchandisePanel from './MerchandisePanel.jsx';
import * as orderUtilities from './OrderProductScreenUtilities.jsx';
import { loggingLevels, nodeRoutes } from '../../../constants.jsx';
import rpcAction from '../../../actions/rpcAction.jsx';
class MerchandisePanelContainer extends Component {
	render() {
		return (
			<MerchandisePanel {...this.props}
				className={'w-100'}	
			/>
		);
	}
}

const mapStateToProps = (state, props) => {
	const selectedCategory = storeUtilities.getValue([props.screenId, 'merchGroup']);
	const wareHouse = storeUtilities.getValue([props.screenId, 'whsNum']) || '';
	const validationErrors = storeUtilities.getValidationErrors([props.screenId]);
	const enableCombineWhsList = storeUtilities.getValue([props.screenId, 'enableCombineWhsList'], false);
	const isPillows = selectedCategory===undefined || selectedCategory==='' 
		? undefined 
		: 'Pillows' === selectedCategory;
	const prodTime = storeUtilities.getValue([props.screenId, 'prodTime'], 0);
	const transitTime = storeUtilities.getValue([props.screenId, 'transitTime'], 0);
	const useLoadFactor = storeUtilities.getValue([props.screenId, 'useLoadFactor'], false);
	const merchGroups = [
		{ value: 'Pillows', name: 'Pillows' },
		{ value: 'Bedding', name: 'Bedding/ Sku Max' },
		{ value: 'Upholstery', name: 'Upholstery/ Rate of Sale' },
		{ value: 'Case Goods', name: 'Case Goods/ Regina' },
		{ value: 'Balanced Ordering', name: 'Balanced Ordering' },
		{ value: 'iSofa Upholstery', name: 'iSofa Upholstery' },
		{ value: 'Special Order', name: 'Special Order' }
	];
	const disableGetSuggestedOrdersButton = 
		validationErrors !== undefined 
		&& (
			'Vendor' in validationErrors
			|| 'txtBuyerInitials' in validationErrors
			|| 'orderDate' in validationErrors
			|| 'merchGroup' in validationErrors
			|| 'whsNum' in validationErrors
			|| ('maxPO' in validationErrors && isPillows === true)
			|| ('poCube' in validationErrors && isPillows === false)
			|| 'beginSalesDate' in validationErrors
			|| 'endSalesDate' in validationErrors
			|| 'salesDays' in validationErrors
			|| 'prodTime' in validationErrors
			|| 'transitTime' in validationErrors
			|| 'daysToNextOrder' in validationErrors
			|| 'daysToStock' in validationErrors
			|| 'marketAdjustment' in validationErrors
			|| 'POAdjustment' in validationErrors
			|| 'txtTotalDays' in validationErrors
			|| ('combineWhsList' in validationErrors && enableCombineWhsList === true)
		);
	const whsIsSavannah = storeUtilities.getValue([props.screenId, 'whsIsSavannah'], false);
	const whsOptions = getWhsOptions(props.divisions);
	const disableCombineWhsList = storeUtilities.getValue([props.screenId, 'enableCombineWhsList'], false) === false || props.divisions.includes('PR');
	const disableCombineWhsListCheckbox = (Array.isArray(props.divisions) && props.divisions.includes('PR'));

	const excludedMomentRanges = storeUtilities.getValue([props.screenId, 'excludedMomentRanges'], []);
	const excludedDatesTooltips = [];
	if(Array.isArray(excludedMomentRanges)){
		excludedMomentRanges.forEach(range => {
			const str = range.start.isSame(range.end, 'day') 
				? range.start.clone().format('L') 
				: (range.start.clone().format('L') + ' - ' + range.end.clone().format('L'));
			excludedDatesTooltips.push(str);
		});
	}
	const categories = useLoadFactor === true ? merchGroups.filter(x => x.value !== 'Balanced Ordering') : merchGroups;
	const transitTimeDisabled = storeUtilities.getValue([props.screenId, 'transitTimeDisabled'], false);
	const featureEnableBulkOrdering = storeUtilities.getValue([props.screenId, 'featureEnableBulkOrdering'], false);
	const disableBulkOrdering = storeUtilities.getValue([props.screenId, 'enableBulkOrdering'], false) === false;
	const bulkOrderingNoCuttingDatesMessage = storeUtilities.getValue([props.screenId, 'bulkOrderingNoCuttingDatesMessage'], '');
	const bulkOrderingCuttingDateRange = storeUtilities.getValue([props.screenId, 'bulkOrderingCuttingDateRange'], '');
	// Mock data for demo purposes only.  The API for this has not been built. 
	const bulkOrderingDateList = getCuttingDates(bulkOrderingCuttingDateRange); 
	return {
		isPillows,
		disableGetSuggestedOrdersButton,
		wareHouse,
		whsOptions,
		disableCombineWhsList,
		disableCombineWhsListCheckbox,
		prodTime,
		transitTime,
		excludedDatesTooltips,
		whsIsSavannah,
		categories,
		transitTimeDisabled,
		disableBulkOrdering,
		bulkOrderingDateList,
		featureEnableBulkOrdering,
		bulkOrderingNoCuttingDatesMessage,
		bulkOrderingCuttingDateRange
	};
};
const getWhsOptions = (divisions) => {
	const whsList = sharedUtilities.getWhsList();
	return Array.isArray(whsList) 
		? whsList
			.filter(whs => {
				return Array.isArray(divisions) ? divisions.includes(whs.division) : false;
			})
			.sort(sharedUtilities.whsDropdownComparator)
			.map(whs => {
				return {
					name: whs.whsName, value: whs.whsId
				};
			})
		: [];
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: (callback) => {
			const skuList = props.skusList ? props.skusList.replace(/\s/g, ''):'';
			const selectedSkusTextArea = props.skusList ? props.skusList : '';
			const numOfSelectedSKUsCountLabel = props.productSearchSelectedRows !== undefined && Array.isArray(props.productSearchSelectedRows) 
				? props.productSearchSelectedRows.length 
				: 0;
			const vendor = props.vendor;
			const maxPerWeek = props.defaultValues.maxPerWeek;
			storeUtilities.updateValue(dispatch, [props.screenId, 'skuList'], skuList, true);
			storeUtilities.updateValue(dispatch, [props.screenId, 'selectedSkusTextArea'], selectedSkusTextArea);
			storeUtilities.updateValue(dispatch, [props.screenId, 'numOfSelectedSKUsCountLabel'], numOfSelectedSKUsCountLabel);
			storeUtilities.updateValue(dispatch, [props.screenId, 'vendor'], vendor, true);
			storeUtilities.updateValue(dispatch, [props.screenId, 'maxPerWeek'], maxPerWeek, true);
			storeUtilities.updateValue(dispatch, [props.screenId, 'excludeRatios'], false);
			const whsIsSavannah = Array.isArray(props.divisions) ? (props.divisions.includes('FL') && props.divisions.includes('SE')) : false;
			storeUtilities.updateValue(dispatch, [props.screenId, 'whsIsSavannah'], whsIsSavannah);
			if(whsIsSavannah === true){
				storeUtilities.updateValue(dispatch, [props.screenId, 'enableCombineWhsList'], true);
				storeUtilities.updateValue(dispatch,[props.screenId, 'whsNum'], 'SE_89');
				const whsOptions = getWhsOptions(props.divisions).map(x => x.value);
				storeUtilities.updateValue(dispatch, [props.screenId, 'combineWhsList'], whsOptions);
			}
			if(callback){
				callback();
			}

			dispatch(rpcAction({
				nodeRoute: nodeRoutes.AllocationChartSubtractionDays,
				showLoadingMask: false,
				callback: (data) => {
					if(data){
						storeUtilities.updateValue(dispatch, [props.screenId, 'allocationChartSubtractionDays'], data.data);
					}
				}
			}));
			dispatch(rpcAction({
				nodeRoute: nodeRoutes.EnableTransitTimeFeature,
				showLoadingMask: false,
				callback: (data) => {
					if(data){
						storeUtilities.updateValue(dispatch, [props.screenId, 'enableTransitTimeFeature'], data.data === 'true');
					}
				},
			}));
			dispatch(rpcAction({
				nodeRoute: nodeRoutes.FeatureEnableBulkOrdering,
				showLoadingMask: false,
				callback: (data) => {
					if(data){
						storeUtilities.updateValue(dispatch, [props.screenId, 'featureEnableBulkOrdering'], data.data === true);
					}
				},
			}));
			dispatch(rpcAction({
				nodeRoute: nodeRoutes.BulkOrderingNoCuttingDatesMessage,
				showLoadingMask: false,
				callback: (data) => {
					if(data){
						storeUtilities.updateValue(dispatch, [props.screenId, 'bulkOrderingNoCuttingDatesMessage'], data.data);
					}
				},
			}));
			dispatch(rpcAction({
				nodeRoute: nodeRoutes.BulkOrderingCuttingDateRange,
				showLoadingMask: false,
				callback: (data) => {
					if(data){
						storeUtilities.updateValue(dispatch, [props.screenId, 'bulkOrderingCuttingDateRange'], data.data);
					}
				},
			}));
			getMaxPOValue(dispatch, props.screenId, props.productSearchSelectedRows);
		},
		goBack: () => {
			dispatch(goBack());
			storeUtilities.deleteValue(dispatch, [props.screenId]);
		},
		calculateTotalDaysAndLeadDate: () => {
			const orderDate = storeUtilities.getValue([props.screenId, 'orderDate'], undefined);
			const prodTime = storeUtilities.getValue([props.screenId, 'prodTime'], 0);
			const transitTime = storeUtilities.getValue([props.screenId, 'transitTime'], 0);
			const daysToNextOrder = storeUtilities.getValue([props.screenId, 'daysToNextOrder'], 0);
			const daysToStock = storeUtilities.getValue([props.screenId, 'daysToStock'], 0);
			const sum = Number(prodTime || 0) + Number(transitTime || 0) + Number(daysToNextOrder || 0) + Number(daysToStock || 0);
			if (orderDate !== undefined) {
				const momentLead = moment(orderDate).add(sum, 'day');
				storeUtilities.updateValue(dispatch, [props.screenId, 'txtTotalDays'], sum.toString());
				storeUtilities.updateValue(dispatch, [props.screenId, 'dtsCal'], momentLead.toDate());
			}
		},
		onGetSuggestedOrdersClicked: () => {
			const selectedCategory = storeUtilities.getValue([props.screenId, 'merchGroup']);
			const excludeRatios = ['Bedding','Upholstery','Pillows','iSofa Upholstery'].includes(selectedCategory);
			storeUtilities.updateValue(dispatch, [props.screenId, 'excludeRatios'], excludeRatios);
			const useCubes = selectedCategory===undefined 
				|| selectedCategory===''
				|| !['Pillows'].includes(selectedCategory);
			if(useCubes === true){
				const useLoadFactor = storeUtilities.getValue([props.screenId, 'useLoadFactor'], false);
				let cubes = storeUtilities.getValue([props.screenId, 'poCube'], undefined);
				if(cubes === undefined){
					return;
				}
				else{
					cubes = parseFloat(cubes);
					if(useLoadFactor === false && (cubes < 1000 || cubes > 4000)){
						/**
						 * According to https://en.wikipedia.org/wiki/Twenty-foot_equivalent_unit ,
						 * the smallest TEU shipping container is 20 ft, which is about 1172 cubic ft
						 * and the largest TEU shipping container is 53 ft, which is about 3604 cubic ft.
						 * To allow for some flexibility
						 * 	1) 	We will consider the lower-bound value to be 1000 and the upper-bound value to be 4000
						 * 	2) 	We will only warn if it is outside thes bounds...yet, still give the user the option to continue
						 * 		if they are sure about the values they have entered
						 */
						dispatch(showDialogAction(
							<OkCancelDialogContainer
								screenId={props.screenId + '_cubeSizeWarning'}
								title={'WARNING'}
								message={'Please verify the PO Cube value entered.'}
								handleOkClicked={() => { getSuggestedOrders(dispatch, props.screenId, props.productSearchSelectedRows); }}
								hasCancelButton>
								You entered {cubes} as the PO Cube value.
								<br /><li>Click OK if this value is correct.</li>
								<li>Click Cancel if this value is not correct.</li>
							</OkCancelDialogContainer>,
							200,
							325,
							() => { }));
					}
					else {
						getSuggestedOrders(dispatch, props.screenId, props.productSearchSelectedRows, props.suggestedOrdersPanelId);
					}
				}
			}
			else {
				getSuggestedOrders(dispatch, props.screenId, props.productSearchSelectedRows, props.suggestedOrdersPanelId);
			}
		},
		handleWarehouseChanged: () => {
			const whs = storeUtilities.getValue([props.screenId, 'whsNum'], undefined);
			storeUtilities.updateValue(dispatch, [props.screenId, 'combineWhsList'], [whs]);
			storeUtilities.deleteValue(dispatch, [props.screenId, 'transitTime']);
			const enableTransitTimeFeature = storeUtilities.getValue([props.screenId, 'enableTransitTimeFeature'], false);
			if(enableTransitTimeFeature === true){
				storeUtilities.updateValue(dispatch, [props.screenId, 'transitTimeDisabled'], true);
				getTransitTime(dispatch, props.screenId, props.productSearchSelectedRows, whs);
			}
		},
		handleCategoryChanged: (value) => {
			const maximizeCube = storeUtilities.getValue([props.screenId, 'maximizeCube'], false);
			if(value === 'Special Order' || value === 'Pillows'){
				orderUtilities.handleSpecialOrderPillowsSelected(dispatch, props.screenId, maximizeCube, value);
			}
			if(value !== 'Case Goods' && value !== 'Balanced Ordering' && value !== 'Upholstery'){
				storeUtilities.deleteValue(dispatch, [props.screenId, 'groupPOsBy']);
			}
		},
		updateProdAndTransTimeValidationError: (isValid, msg, otherFieldId, isInitialValidationCheck) => {
			const validationObject = storeUtilities.getValidationErrors([props.screenId, otherFieldId]);
			const errors = (validationObject !== undefined && Array.isArray(validationObject.errors)) ? validationObject.errors :[];
			if(errors.includes(msg) === false){
				errors.push(msg);
			}
			const filteredErrors = (isValid === true) ? errors.filter((error) => { return error !== msg; }) : errors;
			dispatch(updateValidationErrorsAction([props.screenId, otherFieldId], filteredErrors, isInitialValidationCheck));		
		},
		useLoadFactorChanged: (value) => {
			const selectedCategory = storeUtilities.getValue([props.screenId, 'merchGroup']);
			if(value === true && selectedCategory === 'Balanced Ordering'){
				storeUtilities.deleteValue(dispatch, [props.screenId, 'merchGroup']);
			}
		}
	};
};
const getCuttingDates = (bulkOrderingCuttingDateRange) => {
	const dates = [];
	const currentDate = new Date();
	const monthRange = !isNaN(bulkOrderingCuttingDateRange) ? bulkOrderingCuttingDateRange : 18;
	
	for (let i = 1; i <= monthRange; i++) {
	  const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
	  dates.push({name: date.toLocaleDateString(), value: date});
	}
  
	return dates;
}

const getSuggestedOrders = (dispatch, screenId, productSearchSelectedRows) => {
	storeUtilities.updateValue(dispatch, [screenId, 'disableSuggestedPOsPanel'], true);
	storeUtilities.deleteValue(dispatch, [screenId, 'OrderLinesRowData']);
	storeUtilities.deleteValue(dispatch, [screenId, 'PrimaryOrderLine']);
	storeUtilities.deleteValue(dispatch, [screenId, 'SuggestedPOs']);
	storeUtilities.deleteValue(dispatch, [screenId, 'groupPOsBy']);
	storeUtilities.deleteValue(dispatch, [screenId, 'maximizeCube']);
	storeUtilities.deleteValue(dispatch, [screenId, 'skuAltSkuMapping']);
	
	const whsNum = storeUtilities.getValue([screenId, 'whsNum'], undefined);

	const useTotalDays = storeUtilities.getValue([screenId, 'cbUseTotalDays'], false);
	storeUtilities.updateValue(dispatch, [screenId, 'cbUseTotalDaysInUse'], useTotalDays);
	const prodTime = storeUtilities.getValue([screenId, 'prodTime'], 0);
	const transitTime = storeUtilities.getValue([screenId, 'transitTime'], 0);

	const daysToNextOrder = storeUtilities.getValue([screenId, 'daysToNextOrder'], 0);
	const daysToStock = storeUtilities.getValue([screenId, 'daysToStock'], 0);

	const combineDCsSelected = storeUtilities.getValue([screenId, 'enableCombineWhsList'], false);

	const dateFormat = 'YYYY-MM-DD';
	const excludeDates = orderUtilities.getExcludedMomentRanges([screenId, 'excludedMomentRanges'], 'YYYY-MM-DD');
	const skus = storeUtilities.getValue([screenId, 'skuList'], undefined);
	const combineWhsList = storeUtilities.getValue([screenId, 'combineWhsList'], [whsNum]);
	const args = {
		skuList: skus !== undefined && skus.split ? skus.split(',') : [],
		warehouseList: combineDCsSelected === true ? combineWhsList : [whsNum],
		combineWarehouse: combineDCsSelected,
		salesStart: moment(storeUtilities.getValue([screenId, 'beginSalesDate'])).format(dateFormat),
		salesEnd: moment(storeUtilities.getValue([screenId, 'endSalesDate'])).format(dateFormat),
		productionLeadDays: prodTime,
		transitLeadDays: transitTime,
		daysToNextOrder,
		safetyDays: daysToStock,
		orderMethod: storeUtilities.getValue([screenId, 'merchGroup']),
		marketAdjustment: storeUtilities.getValue([screenId, 'marketAdjustment'], 0),
		poAdjustment: storeUtilities.getValue([screenId, 'POAdjustment'], 0),
		includeIntraDivisionOrders: storeUtilities.getValue([screenId, 'intraOrder'], false),
		excludePromotionalAvailable: storeUtilities.getValue([screenId, 'showPromoAvail'], false),
		excludeDates,
		useTotalDays
	};
	dispatch(rpcAction({
		args,
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/Orders/suggested',
		method: 'POST',
		callback: (data) => {
			let totalOrderQty = 0;
			const usePDMPieceCount = storeUtilities.getValue([screenId, 'usePDMPieceCount'], false);
			if(data !== undefined && Array.isArray(data)){
				data.forEach(row => {
					const selectedRows = Array.isArray(productSearchSelectedRows) ? productSearchSelectedRows.filter(selectedRow => selectedRow.sku === row.sku) : undefined; 
					const orderLine = orderUtilities.createOrderLineFromJsonResult(row);
					if(Array.isArray(selectedRows) && selectedRows.length === 1){
						orderLine.SUBSTITUTIONSKU = selectedRows[0].substitutionSku;
						orderLine.InactiveSKU = selectedRows[0].inactiveSku;
						orderLine.DiscontinuedSKU = selectedRows[0].discontinuedSku;
						orderLine.ToBeDropped = selectedRows[0].toBeDropped;
					}
					else {
						window.console.log('ERROR - too many rows returned by filter');
					}
					const orderValue = parseInt(isNaN(row['order']) ? 0 : row['order']);
					if(orderValue > 0){
						totalOrderQty += orderValue;
					}
					row['ORDERLINE'] = orderLine;
					row['ORIGINAL_ORDERLINE'] = cloneDeep(orderLine);
				});
				const { primaryOrderLine } = orderUtilities.getOrderLinesFromJsonArrayResults(data);
				const orderLinesRowDataOriginal = cloneDeep(data);
				getAllocationData(dispatch, screenId, orderLinesRowDataOriginal);
				storeUtilities.updateValue(dispatch, [screenId, 'PrimaryOrderLine'], primaryOrderLine);
				storeUtilities.updateValue(dispatch, [screenId, 'OrderLinesRowData'], data);
				storeUtilities.updateValue(dispatch, [screenId, 'OrderLinesRowDataOriginal'], orderLinesRowDataOriginal);
				storeUtilities.updateValue(dispatch, [screenId, 'whsNumInUse'], whsNum, true);
				storeUtilities.updateValue(dispatch, [screenId, 'usePDMPieceCountInUse'], usePDMPieceCount);
				storeUtilities.updateValue(dispatch, [screenId, 'totalOrderQty'], totalOrderQty);
				const sizeColumnsToFit = storeUtilities.getValue([screenId, 'sizeSuggestedOrdersOrdersTabGridColumnsToFit'], undefined);
				if (sizeColumnsToFit !== undefined) {
					sizeColumnsToFit();
				}
			}
		}
	}));
};

const getAllocationData = (dispatch, screenId, rowData) => {
	const prodTime = storeUtilities.getValue([screenId, 'prodTime'], 0);
	const Warehouse = storeUtilities.getValue([screenId, 'whsNum'], '');
	const SKU = rowData[0] !== undefined ? rowData[0].ORDERLINE.SKU : '';
	const args = {
		warehouseNumber: Warehouse,
		SKU: SKU,
		productionLeadTime: prodTime
	};
	dispatch(rpcAction({
		args,
		nodeRoute: nodeRoutes.IFRServiceNET,
		endpoint: '/Shipments/requested',
		callback: (data) => {
			if (Array.isArray(data)) {
				storeUtilities.updateValue(dispatch, [screenId, 'AllocationsData'], data);
			}
		},
		showLoadingMask: false,
		customResponseHandler: async (response) => {
			const status = window.parseInt(response.status);
			if(status === 404){
				const message = status + ': ' + response.statusText;
				console.error('Error ' + message);
				sharedUtilities.logMessage(message, loggingLevels.error);
			}
			else {
				return await sharedUtilities.parseResponse(response);
			}
		}
	}));
};

const getMaxPOValue = (dispatch, screenId, selectedRows) => {
	if (selectedRows) {
		const sku = selectedRows[0] !== undefined ? selectedRows[0].sku : '';
		dispatch(rpcAction({
			args: {'input': sku},
			nodeRoute: nodeRoutes.IFRServiceNET,
			endpoint: '/Orders/MaxPurchaseOrder',
			showLoadingMask: false,
			callback: (data) => {
				if (!isNaN(data)){
					storeUtilities.updateValue(dispatch, [screenId, 'maxPerWeek'], String(data));
				}
			},
			customResponseHandler: (response) => {
				if(response.status !== 404){
					return sharedUtilities.parseResponse(response);
				}
			}
		}));
	}
};

const getTransitTime = (dispatch, screenId, selectedRows, whsNum) => {
	if (selectedRows && whsNum) {
		const sku = selectedRows[0] !== undefined ? selectedRows[0].sku : '';
		dispatch(rpcAction({
			args: { 'Sku': sku, 'DistributionCenterId': whsNum },
			nodeRoute: nodeRoutes.IFRServiceNET,
			endpoint: '/Orders/TransitTime',
			showLoadingMask: false,
			callback: (data) => {
				storeUtilities.updateValue(dispatch, [screenId, 'transitTimeDisabled'], false);
				if (isNaN(data) || parseInt(data) === 0){
					storeUtilities.deleteValue(dispatch, [screenId, 'transitTime']);
				}
				else {
					storeUtilities.updateValue(dispatch, [screenId, 'transitTime'], String(data));
				}
			},
			customResponseHandler: (response) => {
				if(response.status !== 404){
					return sharedUtilities.parseResponse(response);
				}
			}
		}));
	}
};

MerchandisePanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	suggestedOrdersPanelId: PropTypes.string.isRequired,
	onGetSuggestedOrdersClicked: PropTypes.func,
	productSearchSelectedRows: PropTypes.array.isRequired,
	vendor: PropTypes.string.isRequired,
	defaultValues: PropTypes.object,
	disableGetSuggestedOrdersButton: PropTypes.bool,
	handleCategoryChanged: PropTypes.func,
	wareHouse: PropTypes.string,
	onLoad: PropTypes.func,
	skusList: PropTypes.string.isRequired,
	useTotalDays: PropTypes.bool,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	categories: PropTypes.arrayOf(PropTypes.object).isRequired,
	useLoadFactorChanged: PropTypes.func
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(MerchandisePanelContainer);
connectedComponent.defaultProps = {
	defaultValues: {
		intraOrder: false,
		maxPerWeek: '0',
		useTotalDays: false
	}
};

export default connectedComponent;