import * as sharedUtilities from '../../../utilities/sharedUtilities';
import { 
	DeleteButtonCellRenderer,
	NumberTextBoxCellEditorContainer,
	NumberTextBoxCellRendererContainer
} from '@rtg/shared-react-components';

const vendorClearanceOrderGridColumnDefs = (screenId, panelId, gridName, getColumnValue, onValueChanged=()=>{}, onEnterPressed, getIsInvalid, getTooltips, onDeleteRowClicked, getFormattedColumnValue, salesCountHeader) => {
	/**
	 * TODO: change the order of the columns to this before making final commit.
	 * [
	 * 	'ROWMODEL_ORIGINAL', //used to track cells that have had their values changed
	 * 	'ROWMODEL', 
	 * 	'SKU', 
	 * 	'DESCRIPTION',
	 *	'CUBE',
	 * 	'QOH', 
	 * 	'QTYONORDER', 
	 * 	'OPENORDERS', 
	 * 	'SALESCOUNT', 
	 * 	'QTYONDISPLAY', 
	 * 	'AVAILABLE', 
	 * 	'AAO', 
	 * 	'DAYSTOSTOCK', 
	 * 	'TARGETQTY', 
	 * 	'ORD' 
	 * ]
	 */
	return [
		{ 
			headerName: '', 
			field: 'ROWMODEL_ORIGINAL', 
			colId: 'ROWMODEL_ORIGINAL', 
			hide: true,
			valueFormatter: getFormattedColumnValue
		},
		{ 
			headerName: '', 
			field: 'ROWMODEL', 
			colId: 'ROWMODEL', 
			hide: true,
			valueFormatter: getFormattedColumnValue 
		},
		{ 
			headerName: 'SKU', 
			field: 'SKU',
			colId: 'SKU',
			width: 100,
			minWidth: 100,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		}, 
		{ 
			headerName: 'Description', 	
			field: 'DESCRIPTION',
			colId: 'DESCRIPTION',
			width: 200,
			minWidth: 200,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		}, 
		{ 
			headerName: 'Cubes', 	
			field: 'CUBE',
			colId: 'CUBE',
			type:'numericColumn',
			width: 65,
			minWidth: 65,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		}, 
		{ 
			headerName: 'Available',
			field: 'AVAILABLE',	
			colId: 'AVAILABLE',
			type:'numericColumn',
			width: 75,
			minWidth: 75,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		}, 
		{ 
			headerName: 'QOH',
			field: 'QOH',
			colId: 'QOH',
			type:'numericColumn',
			width: 90,
			minWidth: 90,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		},
		{ 
			headerName: 'Display',
			field: 'QTYONDISPLAY',
			colId: 'QTYONDISPLAY',
			sortable: true,
			editable: true,
			singleClickEdit:true,
			headerClass: 'ag-center-header',
			cellRenderer: NumberTextBoxCellRendererContainer, 
			cellRendererParams:
			{
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				getIsHidden: () => { return false; },
				getIsInvalid: (params, isRenderer) => getIsInvalid(params, isRenderer),
				getTooltips: (params, isRenderer) => getTooltips(params, isRenderer),
				tooltipPlacement: 'right'
			},
			cellEditor: NumberTextBoxCellEditorContainer, 
			cellEditorParams: 
			{ 
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				getIsHidden: () => {return false;},
				onChange: onValueChanged,
				onEnterPressed: onEnterPressed,
				getIsInvalid: (params, isRenderer) => getIsInvalid(params, isRenderer),
				getTooltips: (params, isRenderer) => getTooltips(params, isRenderer),
				tooltipPlacement: 'right'
			},
			width: 90,
			minWidth: 90,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue,
			type:'numericColumn'
		}, 
		{ 
			headerName: 'Days',
			field: 'DAYSTOSTOCK',
			colId: 'DAYSTOSTOCK',
			sortable: true,
			editable: true,
			singleClickEdit:true,
			headerClass: 'ag-center-header',
			cellRenderer: NumberTextBoxCellRendererContainer, 
			cellRendererParams:
			{
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				getIsHidden: () => { return false; },
				getIsInvalid: (params, isRenderer) => getIsInvalid(params, isRenderer),
				getTooltips: (params, isRenderer) => getTooltips(params, isRenderer),
				tooltipPlacement: 'right'
			},
			cellEditor: NumberTextBoxCellEditorContainer, 
			cellEditorParams: 
			{ 
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				getIsHidden: () => { return false; },
				onChange: onValueChanged,
				onEnterPressed: onEnterPressed,
				getIsInvalid: (params, isRenderer) => getIsInvalid(params, isRenderer),
				getTooltips: (params, isRenderer) => getTooltips(params, isRenderer),
				tooltipPlacement: 'right'
			},
			width: 90,
			minWidth: 90,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue,
			type:'numericColumn'
		}, 
		{ 
			headerName: 'Open', 
			field: 'OPENORDERS',
			colId: 'OPENORDERS',
			type:'numericColumn',
			width: 75,
			minWidth: 75,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		}, 
		{ 
			headerName: 'On Order',	
			field: 'QTYONORDER',
			colId: 'QTYONORDER',
			type:'numericColumn',
			width: 75,
			minWidth: 75,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		}, 
		{ 
			headerName: salesCountHeader,
			field: 'SALESCOUNT',
			colId: 'SALESCOUNT',
			type:'numericColumn',
			width: 75,
			minWidth: 75,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		},
		{ 
			headerName: 'AAO',
			field: 'AAO',
			colId: 'AAO',
			type:'numericColumn',
			width: 75,
			minWidth: 75,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue
		}, 
		{ 
			headerName: 'Target Qty',
			field: 'TARGETQTY',
			colId: 'TARGETQTY',
			sortable: true,
			editable: true,
			singleClickEdit:true,
			headerClass: 'ag-center-header',
			cellRenderer: NumberTextBoxCellRendererContainer, 
			cellRendererParams:
			{
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				getIsHidden: () => { return false; },
				getIsInvalid: (params, isRenderer) => getIsInvalid(params, isRenderer),
				getTooltips: (params, isRenderer) => getTooltips(params, isRenderer),
				tooltipPlacement: 'right'
			},
			cellEditor: NumberTextBoxCellEditorContainer, 
			cellEditorParams: 
			{ 
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				getIsHidden: () => { return false; },
				onChange: onValueChanged,
				onEnterPressed: onEnterPressed,
				getIsInvalid: (params, isRenderer) => getIsInvalid(params, isRenderer),
				getTooltips: (params, isRenderer) => getTooltips(params, isRenderer),
				tooltipPlacement: 'right'
			},
			width: 90,
			minWidth: 90,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue,
			type:'numericColumn'
		}, 
		{ 
			headerName: 'Order',
			field: 'ORD',
			colId: 'ORD',
			sortable: true,
			editable: true,
			singleClickEdit:true,
			headerClass: 'ag-center-header',
			cellRenderer: NumberTextBoxCellRendererContainer, 
			cellRendererParams:
			{
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				tooltipPlacement: 'left',
				getIsHidden: () => { return false; }
			},
			cellEditor: NumberTextBoxCellEditorContainer, 
			cellEditorParams: 
			{ 
				dataType: sharedUtilities.dataTypes.integer,
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				getIsHidden: () => { return false; },
				onChange: onValueChanged,
				onEnterPressed: onEnterPressed
			},
			width: 90,
			minWidth: 90,
			valueGetter: getColumnValue,
			valueFormatter: getFormattedColumnValue,
			type:'numericColumn'
		}, 
		{ 
			headerName: '', 		
			field: 'DELETE',			
			colId: 'DELETE',
			cellRenderer: DeleteButtonCellRenderer, 
			cellRendererParams:
			{
				gridName: gridName,
				screenId: screenId,
				panelId: panelId,
				fieldId: 'deleteRow',
				onClick: onDeleteRowClicked
			},
			width:30,
			minWidth:30,
			resizable: false
		}
	];
};
export default vendorClearanceOrderGridColumnDefs;